import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonModalService {
  heading = new BehaviorSubject<string>("ADD ROLE");
  selectedRoleId = new BehaviorSubject<number>(null);
  editRoleFormData = new BehaviorSubject<any>({});
  private modals: any[] = [];

  add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
    // //console.log("this.modals added",this.modals)
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.element.id !== id);
  }

  open(id: string , data:any, heading?: string) {
    // open modal specified by id
    this.selectedRoleId.next(data?.roleId)
    this.heading.next(heading);
    this.editRoleFormData.next(data);
    this.closeAll()
    const modal = this.modals.find(x => x.element.id === id);
    modal.open();
  }

  close(id: string) {
    // close modal specified by id
    const modal = this.modals.find(x =>x.element.id === id);
    modal.close();
  }
  closeAll(){
    this.modals.forEach(modal => {
      modal.close();
    })
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
@Injectable({providedIn:'root'})
export class SbService {
  anchor_tag = document.createElement('a');
  api_url = environment.API_URL + 'sb/';
  statusTypes = [{ id: 'enabled', value: 'Live' }, { id: 'paused', value: 'Pause' }, { id: 'archived', value: 'Archived' }];
  targetTypes = [{ id: 'auto', value: 'Automatic' }, { id: 'manual', value: 'Manual' }];
  marketplace_id=0;
  constructor(private http:HttpClient,private router:Router) { }
  getParams()
  {
    let fragments=this.router.url.split('/');
    return {brand_id:fragments[3],service_id:fragments[5]}
  }
  campaign={
    getCampaigns:(data)=>{
      const {brand_id,service_id}=this.getParams();
      data.store_id=brand_id;
       return this.http.get(this.api_url + `campaign`,{params:data});
    }
  };
  adGroup={
    getAdGroups:(data)=>{
      const {brand_id,service_id}=this.getParams();
      data.store_id=brand_id;
       return this.http.get(this.api_url + `ad_group`,{params:data});
    }
  };
  keyword={
    getKeywords:(data)=>{
      const {brand_id,service_id}=this.getParams();
      data.store_id=brand_id;
       return this.http.get(this.api_url + `search_term`,{params:data});
    }
  };
  campaign_details={
    getTargeting:(data)=>{
      const {brand_id,service_id}=this.getParams();
      return this.http.get(this.api_url+'campaigns-details/targeting' + `?brand_id=${brand_id}&service_id=${service_id}&marketplace_id=${this.marketplace_id}`);
    },
    getNegativeTargeting:(data)=>{
      const {brand_id,service_id}=this.getParams();
      return this.http.get(this.api_url+'campaigns-details/negative-targeting' + `?brand_id=${brand_id}&service_id=${service_id}&marketplace_id=${this.marketplace_id}`);
    },
    getSearchTerms:(data)=>{
      const {brand_id,service_id}=this.getParams();
      return this.http.get(this.api_url+'campaigns-details/search-terms' + `?brand_id=${brand_id}&service_id=${service_id}&marketplace_id=${this.marketplace_id}`);
    }
  }
  helper={
    getACOS:(spends,sales)=>{
return sales>0?spends/sales*100:0;
    },
    getROAS:(sales,spends)=>{
      return sales/spends;
          }
  }
  downloadCSV(data,name) {
   
    if(!data || !data.length)
      return;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'name');
    XLSX.writeFile(wb, `${name}.xlsx`)
  }
  calculateDateDiff(startDate) {
    var start = moment(startDate);
    var today = moment().subtract(1, "days").toDate();
    //Difference in number of days
    const todayDiff = Math.abs(moment.duration(start.diff(today)).asDays());
    if(todayDiff> 365){
      return  start.add(365, 'day').toDate();
    } else {
      return today;
    }
  } 


  enabled_status={
    product_ads:{enabled:true,paused:true},
    'campaign':{enabled:true,paused:true,archived:true}
  }
  getSatusTypes(type)
  {
    if(!this.enabled_status[type])
    return [];

    return this.statusTypes.filter(e=>e.id in this.enabled_status[type]);

  }

  downloadFile(url,name)
  {
    this.anchor_tag.href = url;
      this.anchor_tag.download = name;
      this.anchor_tag.click();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { CommonModalService } from 'app/shared/component/common-modal/common-modal.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import * as serviveUrl from 'app/shared/commons/service-url';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Account, User } from 'app/shared/models/user.model';

type modaltype = 'addRole' | 'addMember' | 'editRole';
@Injectable({
  providedIn: 'root'
})
export class TeamService {
   servicePrivileges=[];
   usermanagement_state={
     countryList:[],
     stateList:{},
     departments:[],
     reporting_managers:[]
   }
  private request = new BehaviorSubject<{ action: 'open' | 'close', type?: modaltype, data?: any }>(null);
  public requestObservable$ = this.request.asObservable();

  private response = new Subject<any>();
  public responseObservable$ = this.response.asObservable();

  public httpOption;
  constructor(public http: HttpClient, private commonService: CommonModalService) {
    this.httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }


  myTeam(params: any): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.myTeam, { params }).pipe(catchError(this.handleError));
  }

  myTeamByStoreId(params: any): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.myTeam + '/byStoreId', { params }).pipe(catchError(this.handleError));
  }

  getStoresByUId(user_id: any): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.getStoresByUId, { params: { user_id }}).pipe(catchError(this.handleError));
  }
  getCountry(): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.CountryList).pipe(catchError(this.handleError));
  }
  getState(code:string): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.statesList+code).pipe(catchError(this.handleError));
  }
  getDepartments(): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.getDepartments).pipe(catchError(this.handleError));
  }
  getServicePrivileges():any{
    if(this.servicePrivileges?.length) return of(this.servicePrivileges);
    return this.http.get<any>(environment.baseUrl + serviveUrl.getServicesPrivileges).pipe(catchError(this.handleError));
  }
  getRolesByUserId(userId: any): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.getRolesByUid + userId).pipe(catchError(this.handleError));
  }
  getUserById(userId: any): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.getUserById + userId).pipe(catchError(this.handleError));
  }
  getAllUsersByCompany(): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.getAllUsersByCompany).pipe(catchError(this.handleError));
  }
  getAllRolesByCompany(): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.getAllRolesByCompany).pipe(catchError(this.handleError));
  }
  getUserByStoreId(storeId): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.getUserByStoreId + storeId).pipe(catchError(this.handleError));
  }

  getStoresWithStatsByIDs (ids: any): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.getStoresWithStatsByIDs + `?ids=${ids}`).pipe(catchError(this.handleError));
  }

  getUsersByStoreIds(storeIds): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.getUserByStoreId.replace('store_id=', '') + storeIds).pipe(catchError(this.handleError));
  }
  addMemberToStore(data) {
    return this.http.post(environment.baseUrl + serviveUrl.addMemberToStore, data).pipe(catchError(this.handleError));
  }

  updateAccountDetailsByUserId(data) {
    return this.http.post(environment.baseUrl + serviveUrl.updateAccountDetailsByUserId, data).pipe(catchError(this.handleError));
  }

  addRole(data) {
    return this.http.post(environment.baseUrl + serviveUrl.addRole, data).pipe(catchError(this.handleError));
  }
  updateRole(data) {
    return this.http.patch(environment.baseUrl + serviveUrl.updateRole, data).pipe(catchError(this.handleError));
  }

  updateRoleForUsers(data: { role_id: number, users: number[] }) {
    return this.http.put(environment.baseUrl + serviveUrl.updateRoleForUsers, data).pipe(catchError(this.handleError));
  }

  updateAccountDetailsByMemberId(data: any,user_id?) {
    return this.http.patch(`${environment.baseUrl + serviveUrl.updateAccountDetailsByMemberId}/${user_id || data.user_id}`, data).pipe(catchError(this.handleError));
  }
  isMemberAlreadyExists(email)
  {
    return this.http.get(`${environment.baseUrl + serviveUrl.isMemberAlreadyExists+email}`).pipe(catchError(this.handleError));
  }

  deleteMember(data) {
    return this.http.patch(environment.baseUrl + serviveUrl.deleteMemberFromStore, data).pipe(catchError(this.handleError));
  }


  removeMemberFromStore(data) {
    return this.http.get(environment.baseUrl + serviveUrl.removeMemberFromStore+data.user_id+'?store_id='+data.store_id).pipe(catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    let fError;
    if (error) {
      if (error.error) {
        fError = error.error;
      }
    } else {
      fError = {
        error: {}
      }
    }
    // return an observable with a user-facing error message
    return throwError({ message: 'Something bad happened. Please try again later.', error: fError });
  }


  show(type: modaltype, data = null): void {
    this.request.next({
      action: 'open',
      type, data
    });
  }
  close() {
    this.request.next({
      action: 'close'
    })
  }
  responseData(data: any): void {
    this.response.next(data);
  }



  // constructor(private modalService: ModalService) {

  // }

  // show(type: modaltype, data = null) {
  //   this.modalService.open(type, data);
  // }

  // close() {
  //   this.modalService.closeAll();
  // }

}


// import { Injectable } from '@angular/core';
// import { Subject  } from 'rxjs';

// /**
//  * Service to notify local storage values
//  */
// @Injectable()
// export class CommonDataObservableService {
//       private request = new Subject<any>();
//       public requestObservable$ = this.request.asObservable();

//       private response = new Subject<any>();
//       public responseObservable$ = this.response.asObservable();

//       constructor() { }

//       sendData(data: any): void {
//           this.request.next(data);
//       }

//       responseData(data: any): void {
//         this.response.next(data);
//       }
// }


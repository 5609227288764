
<div class="tab-main-wrapper">
    <div class="nav-select" style="padding-top: 10px;">
        <nav mat-tab-nav-bar>
            <a mat-tab-link
            
            *ngFor="let link of navLinks"
            [routerLink]="setRoute(link.link)"
            routerLinkActive #rla="routerLinkActive"
            [active]="rla.isActive">
            {{link.label}}
            </a>
            </nav>
<div class="layout-filter-wrapper">
    <app-market-place-dropdown (onChangeSelection)="changeMarketplace($event)"></app-market-place-dropdown>
</div>
        <!-- <div class="language-slect">   
            <button  mat-icon-button [matMenuTriggerFor]="language" aria-label="Example language select">
              
                <span>{{selected_integration.integration_name}}</span>
            </button>
            <mat-menu #language="matMenu"  class="language-dropdown">
                <button (click)="selected_integration=c" *ngFor="let c of integrations" mat-menu-item>
                  
                    <span>{{c.integration_name}}</span>
                </button>
            </mat-menu>
        </div> -->
    </div>

    <router-outlet></router-outlet>
</div>

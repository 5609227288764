export const application_url=(url)=>
{
  let urls=url.split('/');
        urls.length=7;
  return urls.join('/')+'/';
}


export const AD_TYPES={
  'product':'Sponsored Products',
  'brand':'Sponsored Brands',
  'display':'Sponsored Display'
}





const enabled_status={
    product_ads:{enabled:true,paused:true},
    'campaign':{enabled:true,paused:true,archived:true}
  };

const statusTypes = [{ id: 'enabled', value: 'Live' }, { id: 'paused', value: 'Pause' }, { id: 'archived', value: 'Archived' }];

export const targetTypes = [{ id: 'auto', value: 'Automatic' }, { id: 'manual', value: 'Manual' }];


export const  getSatusTypes=(type)=>
{
  if(!enabled_status[type])
  return [];

  return statusTypes.filter(e=>e.id in enabled_status[type]);

};


export const getQueryParams=(url)=>
{
    let fragments=url.split('/');
    return {brand_id:fragments[3],service_id:fragments[5]};
}

const days=['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
export const dayWithNumber=()=>{
let dayObj={};
days.forEach((e,i)=>{
  dayObj[i+1]=e;
});
return dayObj;
}



export const formatTime=(day)=>{
return day>12?day-12+'PM':(day||12)+'AM';
}
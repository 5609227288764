import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getServiceByStoreId } from 'app/shared/commons/service-url';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { Subject } from 'rxjs';
import { PpcDashboardService } from '../../dashboards/services/ppc-dashboard.service';
import { SharedService } from '../services/shared.service';

const tabs = 
[
  {
  label: 'Dashboard',
  link: './dashboard',
  index: 0
},
 {
  label: 'Sponsored Products',
  link: './sponsored-products',
  index: 0
},
 {
  label: 'Sponsored Brands',
  link: './sponsored-brands',
  index: 1
}, {
  label: 'Sponsored Display',
  link: './sponsored-display',
  index: 2
},
{
  label: 'Scheduling',
  link: './scheduling',
  index: 0
},
{
  label: 'Category Dashboard',
  link: './upload-file',
  index: 3
}
]

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  navLinks = tabs;
  selected_integration = null;
  isDashboardOpened:any=false;
  constructor(private service: SharedService,private ppcDashboardService:PpcDashboardService, private dashboardService: DashboardService, private route: ActivatedRoute) { }
  integrations = [];
  ngOnInit(): void {
    this.service.header_tabs_status.next(true);
    this.ppcDashboardService.is_opened$.asObservable().subscribe(e=>{
      this.isDashboardOpened=e;
    })
  }
  ngOnDestroy() {
    this.service.header_tabs_status.next(false);

  }

  changeMarketplace(data)
  {
    console.log(data)
  }
  setRoute(name) {
    return name;
  }


  applydateFilter(event)
  {
    this.ppcDashboardService.selected_date$.next(event);
  }


}

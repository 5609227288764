import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import * as serviveUrl from 'app/shared/commons/service-url';
@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
     base_url=environment.baseUrl+'/api/v1';
     marketplaces:any={}
  constructor(public http: HttpClient) { }

  onboardStore(data: any): Observable<any> {
    return this.http.post<any>(this.base_url + serviveUrl.onboardStore,data);
  }
  getIntegrationTypeByService(data): Observable<any> {
    let integration_type=(data.type&&data.type.length)?data.type.map((e,i)=>!i?`?type=${encodeURIComponent(e)}`:`&type=${encodeURIComponent(e)}`).join(''):'';
    return this.http.get<any>(this.base_url+ serviveUrl.getIntegrationTypeByService+data.service_id+integration_type);
  }
  getIntegrationsByTypeAndService(data): Observable<any> {
    let integration_type=(data.type&&data.type.length)?data.type.map((e,i)=>`&type=${encodeURIComponent(e)}`).join(''):'';
    return this.http.get<any>(this.base_url+ serviveUrl.getIntegrationByTypeAndService+data.service_id+`?store_id=${data.store_id}`+integration_type);
  }
  verfiyIntegrationCredentials(data): Observable<any> {
    return this.http.post<any>(this.base_url+ serviveUrl.verifyIntegrationCredentials,data);
  }

  changeServiceIntegrationStatus(data): Observable<any> {
    
    return this.http.patch<any>(this.base_url+ serviveUrl.changeServiceIntegrationStatus,data);
  }
   changeStoreStatus(data){
    return this.http.patch<any>(this.base_url+ serviveUrl.changeStoreStatus,data);
   }

   getServiceDetails(data){
    return this.http.get<any>(this.base_url+ serviveUrl.getServiceDetails,{params:data});
   }

   submitServiceEnquiry(data)
   {
    return this.http.post<any>(this.base_url+ serviveUrl.submitServiceEnquiry,data);
   }

   amazon={

    getAuth:(data)=>{
      return this.http.get<any>(this.base_url+ serviveUrl.amazon.getSellerAuth,{params:data});
    },
    verifyIntegration:(data)=>{
      return this.http.get<any>(this.base_url+ serviveUrl.amazon.verifyIntegration,{params:data});
    }
   }
}

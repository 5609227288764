import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import * as PPC_CONFIG from 'app/modules/ppc/ppc.config';

@Injectable({
  providedIn:'root'
})
export class PortfolioService {
  api_url=environment.API_URL+'portfolios/';
  selected_portfolios=new BehaviorSubject([]);
  portfolios=[];
  // portfolios:any[]=[]
  campaigns:any[]= [
    {id:1,checkbox: false, campaignname: 'Winter Sale', campaigntype: 'Sponsored Products', startdate: '10 Oct 2020', enddate: '$15.00', sales: '$20,000', spends: '$2,000', acos:'15%', status:'Out Of Budget'},
    {id:2,checkbox: false, campaignname: 'Black Friday', campaigntype: 'Sponsored Brands', startdate: '10 Oct 2020', enddate: '$15.00', sales: '$20,000', spends:'$2,000', acos:'15%',  status:'Live'},
    {id:3,checkbox: false, campaignname: 'End of the Season', campaigntype: 'Sponsored Products', startdate: '10 Oct 2020', enddate: '$15.00', sales: '$20,000', spends:'$2,000', acos:'15%', status:'Live'},
    {id:4,checkbox: false, campaignname: 'Festive Fusion', campaigntype: 'Sponsored Products', startdate: '10 Oct 2020', enddate: '$15.00', sales: '$20,000', spends:'$2,000', acos:'15%', status:'Paused'},
    {id:5,checkbox: false, campaignname: 'Xmas Sale', campaigntype: 'Sponsored Products', startdate: '10 Oct 2020', enddate: '$15.00', sales: '$20,000', spends:'$2,000', acos: '15%', status:'Live'},
  ];
  campaign_types:any[]=[{id:1,value:'Sponsored Products'},{id:2,value:'Sponsored Brands'},{id:1,value:'Sponsored Display'}];
  
  statusTypes=[{id:'live',value:'Live'},{id:'pause',value:'Pause'},{id:'out_of_budget',value:'Out Of Budget'}];
  targetTypes=[{id:'automatic',value:'Automatic'},{id:'manual',value:'Manual'}];
  constructor(private http:HttpClient,private router:Router) { }
  portfolio={
    list:async (data)=>{
      try{
        const {brand_id:store_id}=PPC_CONFIG.getQueryParams(this.router.url);
        let current_portfolio=this.portfolios.find(e=>e.marketplace_id==data.marketplace_id&&e.store_id==store_id);
        if(current_portfolio){
          this.selected_portfolios.next(current_portfolio.data);
          return;
        }
        let res:any=await this.http.get(this.api_url+'list',{params:{...data,store_id}}).toPromise();
        this.portfolios.push({store_id,marketplace_id:data.marketplace_id,data:res.data});
        this.selected_portfolios.next(res.data);
      }
      catch(ex)
      {
        console.log(ex);
      }

    },
    getPortfolios:()=>{
      if(this.portfolios.length)
      return this.portfolios;
  return  new Promise(async (resolve,reject)=>{
      try{
        let data:any=await this.http.get(this.api_url+'allportfolios').toPromise();
        this.portfolios=data;
        resolve(this.portfolios);
    }catch(ex){
    
    reject(ex);
    }
     })
    },getPortfolioByName:(name)=>{

      if(this.portfolios.length)
      {
        return this.portfolios.find(e=>e.portfolio_name==name);
      }
      return {};
      
    },
    addPortfolio:(p)=>
  {
    return this.http.post(this.api_url+'add-portfolio',p).toPromise();
     
  },
 updatePortfolio:(p)=>
  {
    p.budgettype=p.budgettype=='1'?'No Budget Cap':p.budgettype=='2'?'Date Range':'Recurring';
    for(let i=0;i<this.portfolios.length;i++)
     {
       if(this.portfolios[i].id==p.id)
       {
         this.portfolios[i]=p;
       }
     }
   
  }  
  };
  campaign={
    getPortfolioCampaigns:(name)=>{
      return this.http.get(this.api_url+'get-portfolio-campaigns?portfolio_name='+name).toPromise()
    },
    addCampaign:(c,portfolio_name)=>
  {
   for(let i=0;i<this.portfolios.length;i++)
   {
     if(this.portfolios[i].portfolios==portfolio_name){
       let c_id='';
       c.forEach((e,i)=>{
        c_id=c_id+e.id;
        if(i<c.length-1)
        {
          c_id=c_id+',';
        }
        console.log(i,c_id,e.id)
       });
       this.portfolios[i].campaign_id=c_id;
       console.log(this.portfolios[i])
break;
     }
   }
  //  this.router.navigateByUrl('portfolio/'+portfolio_name)

},getCampaigns:async ()=>{
  if(this.campaigns.length)
  return this.campaigns;
  try{
    let data:any=this.http.get(this.api_url+'campaigns').toPromise();
    this.campaigns=data;
    return this.campaigns;
}catch(ex){console.log(ex);}
  }
  }
}

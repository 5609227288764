import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as API_CONFIG from 'app/shared/commons/service-url'; 
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfitabilityService {

  private readonly uploadUrl = `${environment}/upload`;

  constructor(private http: HttpClient) {}


uploadProfitabilityData(data: any) {
  const formData = new FormData();
formData.append('file', data.file);
const url = `${environment.baseUrl}/api/v1/dataone/profitability-metrics/upload-cogs`;
const params = new HttpParams()
.set('service_integration_id', data.service_integration_id)
   .set('service_id', data.service_id)
   .set('store_id', data.store_id)
   .set('user_id', data.user_id)
   .set('username', data.name)
   .set('effective_date', data.effective_date);
  return this.http.post(url, formData, { params });
}


  channelwise(data)
  {
    return this.http.get(environment.baseUrl+ '/api/v1/dataone/profitability-metrics/channel-profit',{params:data}).toPromise();
  }
  channelwisecomparison(data)
  {
    return this.http.get(environment.baseUrl+ '/api/v1/dataone/profitability-metrics/channel-comparison-profit',{params:data}).toPromise();
  }
  prductprofit(data)
  {
    return this.http.get(environment.baseUrl+ '/api/v1/dataone/profitability-metrics/product-profit',{params:data}).toPromise();
  }
  productportfolioprofit(data: any) : Observable<any> {
    return this.http.get(environment.baseUrl+ '/api/v1/dataone/profitability-metrics/product-portfolio-profit',data);
  }
  producttrendprofit(data)
  {
    return this.http.get(environment.baseUrl+ '/api/v1/dataone/profitability-metrics/product-trend-profit',{params:data}).toPromise();
  }
  producttrendexpansprofit(data)
  {
    return this.http.get(environment.baseUrl+ '/api/v1/dataone/profitability-metrics/product-level-trend-profit',{params:data}).toPromise();
  }
  
  productleveltrendprofit(data)
  {
    return this.http.get(environment.baseUrl+ '/api/v1/dataone/profitability-metrics/product-trend-profit',{params:data}).toPromise();

  }

}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import * as XLSX from 'xlsx';



@Injectable({providedIn:'root'})
export class SdService {
  anchor_tag = document.createElement('a');
  api_url = environment.API_URL + 'sd/';
  statusTypes = [{ id: 'enabled', value: 'Live' }, { id: 'paused', value: 'Pause' }, { id: 'archived', value: 'Archived' }];
  targetTypes = [{ id: 'auto', value: 'Automatic' }, { id: 'manual', value: 'Manual' }];
  user_data=null;
  marketplace_id=0;
  constructor(private http:HttpClient,private router:Router) { }


  getUser()
{
  if(!this.user_data)
  {
    this.user_data=JSON.parse(localStorage.getItem('user'));
  }
  return this.user_data;
}


getParams()
{
  let fragments=this.router.url.split('/');
  return {brand_id:fragments[3],service_id:fragments[5]}
}
  campaign={
    getCampaigns:(data)=>{
      const {brand_id,service_id}=this.getParams();
      data.store_id=brand_id;
       return this.http.get(this.api_url + `campaign`,{params:data});
    }
  };
  adGroup={
    getAdGroups:(data)=>{
      const {brand_id,service_id}=this.getParams();
      data.store_id=brand_id;
      
       return this.http.get(this.api_url + `ad_group`,{params:data});
    }
  };
  Target={
    getTargets:(data)=>{
      const {brand_id,service_id}=this.getParams();
      data.store_id=brand_id;
      
       return this.http.get(this.api_url + `target`,{params:data});
    }
  }
  productAds = {
    getProductAds: (data) => {
      const {brand_id}=this.getParams();
      data.store_id=brand_id;
      // const query_string=`brand_id=${brand_id}&marketplace_id=${data.marketplace_id || ''}&status=${data.status}&page=${data.page}&start_date=${data.start_date}&end_date=${data.end_date}&search_param=${data.search_param}&page_size=${data.page_size || 10}`;
      return this.http.get(this.api_url + 'product_ads',{params:data});
    },
    updateAdState:(data)=>{
      const {brand_id:store_id}=this.getParams();
      return this.http.post(this.api_url + 'product_ads/update-ad-state',{...data,store_id});
    }
  };
   helper={
    getACOS:(spends,sales)=>{
return sales>0?spends/sales*100:0;
    },
    getROAS:(sales,spends)=>{
      return sales/spends;
          }
  }
  downloadCSV(data,name) {
   
    if(!data || !data.length)
      return;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'name');
    XLSX.writeFile(wb, `${name}.xlsx`)
  }

  downloadFile(url,name)
  {
    this.anchor_tag.href = url;
      this.anchor_tag.download = name;
      this.anchor_tag.click();
  }

  enabled_status={
    product_ads:{enabled:true,paused:true},
    'campaign':{enabled:true,paused:true,archived:true}
  }
  getSatusTypes(type)
  {
    if(!this.enabled_status[type])
    return [];

    return this.statusTypes.filter(e=>e.id in this.enabled_status[type]);

  }

}
